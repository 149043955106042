import { isNil, map, toLower } from 'lodash';
import moment, { Moment, MomentInput, unitOfTime } from 'moment';
import momentTZ from 'moment-timezone';

export type DateInstance = Moment;

export const formatDate = (
    targetFormat: string = null,
    date?: Date | string | Moment,
    currentFormat: string = undefined,
    timezone: string = null
): string => {
    if (timezone) {
        return momentTZ(date, currentFormat).tz(timezone).format(targetFormat);
    }
    return targetFormat ? moment(date, currentFormat).format(targetFormat) : moment(date, currentFormat).format();
};
export const dateToISOString = (date?: Date | string | Moment, preventUTCConversion = false): string =>
    moment(date).toISOString(preventUTCConversion);
export const dateToUTCString = (date?: Date | string | Moment, locale?: string): string =>
    locale ? moment(date).locale(locale).utc().format() : moment(date).utc().format();
export const dateToUTCInstance = (date?: Date | string | Moment, timezone?: string, locale?: string): Date | Moment =>
    locale
        ? timezone
            ? momentTZ.tz(date, timezone).locale(locale).utc()
            : moment(date).locale(locale).utc()
        : timezone
        ? momentTZ.tz(date, timezone).utc()
        : moment(date).utc();
// @todo: deprecate this method once we move to a new library
export const cloneDateInstance = (value: Moment): Moment => value.clone();
export const addDate = (
    value: moment.DurationInputArg1,
    unit: moment.unitOfTime.DurationConstructor,
    currentTime?: Date | string | Moment
): string | Moment | Date => moment(currentTime).add(value, unit);
export const subtractDate = (
    value: moment.DurationInputArg1,
    unit: moment.unitOfTime.DurationConstructor,
    currentTime?: Date | string | Moment
): string | Moment | Date => moment(currentTime).subtract(value, unit);
export const diffDate = (
    currentDate: Date | string | Moment,
    targetDate: Date | string | Moment,
    unit?: moment.unitOfTime.Diff
): number => moment(currentDate).diff(targetDate, unit);
export const toDate = (value?: Date | string | Moment, format?: string): Date => moment(value, format).toDate();
export const getCurrentLocaleTimestamp = (locale = 'en'): string => moment().locale(locale).format();
export const durationAsHours = (value: moment.DurationInputArg1): number => moment.duration(value).asHours();
export const getCurrentTimezone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone;
export const getCurrentTimezoneAbbr = (): string => moment().tz(getCurrentTimezone()).zoneAbbr();
export const isBeforeDate = (
    beforeDate: Date | string | Moment,
    afterDate: Date | string | Moment,
    granularity?: unitOfTime.StartOf
): boolean => moment(beforeDate).isBefore(afterDate, granularity);
export const isAfterDate = (
    beforeDate: Date | string | Moment,
    afterDate: Date | string | Moment,
    unit?: moment.unitOfTime.StartOf
): boolean => moment(beforeDate).isAfter(afterDate, unit);
export const isSameDate = (
    value1: Date | string | Moment,
    value2: Date | string | Moment,
    unit?: moment.unitOfTime.StartOf
): boolean => moment(value1).isSame(value2, unit);
export const isSameOrBeforeDate = (
    value1: Date | string | Moment,
    value2: Date | string | Moment,
    unit?: moment.unitOfTime.StartOf
): boolean => moment(value1).isSameOrBefore(value2, unit);
export const isSameOrAfterDate = (
    value1: Date | string | Moment,
    value2: Date | string | Moment,
    unit?: moment.unitOfTime.StartOf
): boolean => moment(value1).isSameOrAfter(value2, unit);
export const isBetweenDates = (
    start: Date | string | Moment,
    end: Date | string | Moment,
    currentTime?: Date | string | Moment,
    unit?: moment.unitOfTime.StartOf,
    inclusivity?: '()' | '[)' | '(]' | '[]'
): boolean => moment(currentTime).isBetween(start, end, unit, inclusivity);
export const isValidDate = (date?: Date | string | Moment, currentFormat?: string, strict?: boolean): boolean =>
    moment(date, currentFormat, strict).isValid();
export const startOfDate = (date: Date | string | Moment, unit: moment.unitOfTime.StartOf): string | Date | Moment =>
    moment(date).startOf(unit);
export const endOfDate = (date: Date | string | Moment, unit: moment.unitOfTime.StartOf): Moment =>
    moment(date).endOf(unit);
export const getHours = (date?: Date | string | Moment): number => moment(date).hours();
export const setHours = (value: number, date?: Date | string | Moment): Moment => moment(date).hours(value);
export const getMinutes = (date?: Date | string | Moment): number => moment(date).minutes();
export const setMinutes = (value: number, date?: Date | string | Moment): Moment => moment(date).minutes(value);
export const getSeconds = (date?: Date | string | Moment): number => moment(date).seconds();
export const setSeconds = (value: number, date?: Date | string | Moment): Moment => moment(date).seconds(value);
export const getMiliseconds = (date?: Date | string | Moment): number => moment(date).milliseconds();
export const setMiliseconds = (value: number, date?: Date | string | Moment): Moment =>
    moment(date).milliseconds(value);
export const getDate = (date?: Date | string | Moment): number => moment(date).date();
export const setDate = (value: number, date?: Date | string | Moment): Moment => moment(date).date(value);
export const getDay = (date?: Date | string | Moment): number => moment(date).day();
export const setDay = (value: number, date?: Date | string | Moment): Moment => moment(date).day(value);
export const getMonth = (date?: Date | string | Moment): number => moment(date).month();
export const setMonth = (value: number, date?: Date | string | Moment): Moment => moment(date).month(value);
export const getYear = (date?: Date | string | Moment): number => moment(date).year();
export const setYear = (value: number, date?: Date | string | Moment): Moment => moment(date).year(value);
export const getDateLocalized = (date?: Date | string | Moment): string => moment(date).locale();
export const setDateLocalized = (locale: string | string[] = 'en', date?: Date | string | Moment): Moment =>
    moment(date).locale(locale);
export const setGlobalDateLocale = (locale: string | string[] = 'en') => moment.locale(locale as string);
export const getDateInstance = (
    value?: Date | string | Moment,
    targetFormat?: string,
    language?: string,
    strict?: boolean
): Moment => moment(value, targetFormat, language, strict);
export const fromNow = (date: Date | string | Moment, withoutSuffix?: boolean, language?: string): string =>
    getDateInstance(date, null, language).fromNow(withoutSuffix);
export const dateRelativeTimeRounding = (
    roundingFunction?: (num: number) => number
): boolean | ((num: number) => number) => moment.relativeTimeRounding(roundingFunction);
export const fromUnixTimestamp = (value: number): Moment => moment.unix(value);
export const isAwsDateString = (str: string): boolean => {
    // Credit: https://stackoverflow.com/questions/3143070/javascript-regex-iso-datetime -- Brock Adams
    const regex =
        /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/;
    return regex.test(str);
};
export const durationAsMinutes = (value: moment.DurationInputArg1): number => moment.duration(value).asMinutes();
export const getUnixTimeStamp = (date?: Date | string | Moment) => moment(date).valueOf();
export const getMinDate = (dates: (Date | string | Moment)[], format?: string): Moment =>
    moment.min(map(dates, (e: MomentInput) => moment(e, format)));
export const getMaxDate = (dates: (Date | string | Moment)[], format?: string): Moment =>
    moment.max(map(dates, (e: MomentInput) => moment(e, format)));
export const setTimeToDate = (date: Date | string | Moment, timeToAdd: string = null): Date => {
    const minTime = !isNil(timeToAdd) ? `${formatDate('YYYY-MM-DD')} ${timeToAdd}` : date;
    let newDate = setHours(getHours(minTime), date);
    newDate = setMinutes(getMinutes(minTime), newDate);
    newDate = setSeconds(0, newDate);
    newDate = setMiliseconds(0, newDate);
    return toDate(newDate);
};
export const setTimeUnits = (
    date: Moment | Date | string,
    values: { hours?: number; minutes?: number; seconds?: number; milliseconds?: number }
): Date => {
    const { hours = 0, minutes = 0, seconds = 0, milliseconds = 0 } = values;
    let newDate = setHours(hours, date);
    newDate = setMinutes(minutes, newDate);
    newDate = setSeconds(seconds, newDate);
    newDate = setMiliseconds(milliseconds, newDate);
    return toDate(newDate);
};
export const importDateLocale = async (lang: string): Promise<unknown> => {
    const languageKey = toLower(lang);
    let response;
    try {
        response = await import(/* @vite-ignore */ `moment/locale/${languageKey}`);
    } catch (error) {
        try {
            response = await import(/* @vite-ignore */ `moment/locale/${languageKey.substring(0, 2)}`);
        } catch (err) {
            try {
                response = await import(/* @vite-ignore */ `common/i18n/locales/${languageKey.substring(0, 2)}-moment`);
            } catch (e) {}
        }
    }

    return response;
};
