import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';
export const routes = [
    {
        component: lazy(() => import('module/managesites/ManageSitesListPage')),
        permissions: ['StudySite_Edit', 'StudySite_Create', 'StudySite_Delete', 'Study_Status_Edit'],
        path: '/manageSites/:studyId',
        role: [BaseRoles.AdminId, RoleVariantEnum.StudyBuilder, RoleVariantEnum.StudySupport],
    },
    {
        component: lazy(() => import('module/managesites/RecordsDashboardsPage')),
        permissions: ['EOSDownload', 'EOSDownloadRecord_Read', 'EOSDownloadRecord_Edit'],
        path: '/manageSites/:studyId/:siteId/records/:type',
        role: [BaseRoles.AdminId, RoleVariantEnum.StudySupport],
    },
];
