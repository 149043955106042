import { RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';

export let routes = [
    {
        component: lazy(() => import('module/region/list/region_list.page')),
        permissions: [
            'SupportedAwsRegion_Create',
            'SupportedAwsRegion_Edit',
            'SupportedAwsRegion_Delete',
            'SupportedAwsRegion_Read',
        ],
        role: [RoleVariantEnum.SystemAdminstrator],
        path: '/',
    },
];
