import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';

export const routes = [
    {
        component: lazy(() => import('module/studysitealerts/studysitealerts.page')),
        permissions: [],
        role: [BaseRoles.AdminId, RoleVariantEnum.StudyBuilder, RoleVariantEnum.StudySupport],
        path: '/alerts/:studyId/:alertId?',
    },
];
