import { browserHistory } from 'common/routing/browser_history';
import { AuthUser } from '../auth/auth_context';
import { Analytics } from './analytics.provider';

export default class GoogleAnalytics implements Analytics {
    public initialize(): void {
        browserHistory.listen((location): void => {
            if (window['ga']) {
                // @ts-ignore
                window.ga('set', 'page', location.pathname + location.search);
                // @ts-ignore
                window.ga('send', 'pageview');
            }
        });
    }

    public identifyUser(user: AuthUser): void {}
    public anonymousUser(pendoObj: string): void {}
    public clearSession(): void {}

    public track(): void {}
}
