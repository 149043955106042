export const STUDY_ID_REGEX = /Study.[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/g;
export const STUDY_VERSION_URL_REGEX =
    /studydetails\/Study.[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}\/version/gi;
export const ECONSENT_REGEX = /^\/econsentbuilder\/Study/;

export const MISSING_ITEM_LABEL = 'Undefined';
export const PENDING_MIGRATION_ITEM_LABEL = MISSING_ITEM_LABEL;

export const initialStudyActivityVersionStr = '0.1';
export const defaultCountLimit = 200;
