import { RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';
export let routes = [
    {
        component: lazy(() => import('module/subscriber/list/subscriber_list.page')),
        permissions: ['Subscriber_Create'],
        role: [RoleVariantEnum.SystemAdminstrator],
        path: '/',
    },
];
