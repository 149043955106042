import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { preloadLazyComponent } from 'common/utils/loading';
export let routes = [
    {
        component: preloadLazyComponent(() => import('module/activitybuilder/datamapping.page')),
        permissions: ['Customer_Read'],
        role: [BaseRoles.AdminId, BaseRoles.UserId, RoleVariantEnum.StudyBuilder, RoleVariantEnum.StudySupport],
        path: '/:activityId/datamapping',
    },
    {
        component: preloadLazyComponent(() => import('module/activitybuilder/activitybuilder.page')),
        permissions: ['Customer_Read'],
        role: [
            BaseRoles.AdminId,
            BaseRoles.UserId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.Librarian,
        ],
        path: '/:activityId/:activityDesignerTab?',
    },
    {
        component: preloadLazyComponent(() => import('module/activitybuilder/activitytranslate.page')),
        permissions: ['Customer_Read'],
        role: [
            BaseRoles.AdminId,
            BaseRoles.UserId,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.TranslationRepresentative,
        ],
        path: '/:activityId/translate/:targetLng/:activityTranslateTab?',
    },
];
