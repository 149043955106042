import { withApollo } from '@apollo/client/react/hoc';
import { Capacitor } from '@capacitor/core';
import classNames from 'classnames';
import withAnalytics, { CIAnalytics } from 'common/analytics/analytics.provider';
import GoogleAnalytics from 'common/analytics/googleanalytics';
import PendoAnalytics from 'common/analytics/pendo';
import { AuthActions, AuthState } from 'common/auth/auth_context';
import { BaseRoles, RoleVariantEnum, UserSubType, UserType } from 'common/graphql/types';
import { AuditLoggerProvider } from 'common/log/ci.auditlogger';
import CIAppLogger from 'common/log/ci.logger';
import ConsoleLogger from 'common/log/console.logger';
import LoggerProvider, { CILogger } from 'common/log/log.provider';
import 'common/sentry/sentry';
import Telehealth from 'common/telehealth';
import { ComponentWithoutHOCProps } from 'common/types/component';
import { SuspenseFallback } from 'common/utils/loading';
import CINetworkBanner from 'common/viewcomp/general/offline/network-banner/ci_network_banner';
import { flow } from 'lodash';
import { DEFAULT_PATH } from 'module/auth/login/login.utils';
import { ReactElement, Suspense, useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import AppRoutes from 'routes';
import './app.scss';

declare global {
    interface Window {
        pendo?: {
            visitorId: string;
            identify: (
                visitor: {
                    id: string;
                    email: string;
                    userCreation: string;
                    username: string;
                    userType: UserType;
                    subType: UserSubType;
                    language: string;
                    preferredLanguage: string;
                    studyId: string;
                    siteId: string;
                    baseRole: BaseRoles;
                    roleVariant: RoleVariantEnum;
                    device: string;
                    operatingSystem: string;
                    osVersion: string;
                },
                account: { id: string; name?: string; createDate?: string }
            ) => void;
            clearSession: () => void;
        };
    }
}

let analytics = CIAnalytics.getInstance();
analytics.registerProvider(new GoogleAnalytics());
analytics.registerProvider(new PendoAnalytics());
analytics.initialize();

let logging = CILogger.getInstance();
if (process.env.NODE_ENV !== 'production') {
    logging.registerProvider(new ConsoleLogger());
}
logging.registerProvider(new CIAppLogger());
logging.initialize();

function App(): ReactElement {
    const store = useStore<{ auth: AuthState }>();
    const { auth } = store.getState();
    const dispatch = useDispatch();

    // Update Pendo if undefined
    if (auth.activeUser?.pk && window.pendo?.visitorId !== auth?.activeUser?.pk) {
        CIAnalytics.getInstance().identifyUser(auth.activeUser);
    }

    useEffect(() => {
        if (localStorage.getItem('switchUserDialog') && localStorage.getItem('activeUser')) {
            dispatch(AuthActions.handleSwitchUser(true));
        }

        const authListener = (event: StorageEvent) => {
            if (event.key === 'activeUser' && !event.newValue) {
                dispatch(AuthActions.logoutRequest());
            }
            if (event.key === 'activeUser' && event.newValue && event.newValue !== event.oldValue) {
                dispatch(AuthActions.handleSwitchUser(false));
                sessionStorage.removeItem('activeStudySite');
                localStorage.removeItem('switchUserDialog');
                window.location.pathname = DEFAULT_PATH;
            }
            if (event.key === 'switchUserDialog') {
                dispatch(AuthActions.handleSwitchUser(+event.newValue > 0));
            }
        };

        window.addEventListener('storage', authListener);

        return () => window.removeEventListener('storage', authListener);
    }, []);

    return (
        <div
            id="app_container"
            className={classNames({
                'televisit_thumbnail': auth.thumbnailMode,
            })}
        >
            {auth.activeMeeting && (
                <Telehealth
                    {...(auth.meetingDetails || {})}
                    closeParent={(): void => {
                        dispatch(AuthActions.updateTelevisitStatus({ id: '', name: '', isAdmin: '' }, false));
                    }}
                />
            )}
            <Suspense fallback={<SuspenseFallback />}>
                {Capacitor.isNativePlatform() && <CINetworkBanner />}
                <AppRoutes />
            </Suspense>
        </div>
    );
}

const AppWrapper: ComponentWithoutHOCProps<{}> = flow([withApollo, withAnalytics, LoggerProvider, AuditLoggerProvider])(
    App
);

export default AppWrapper;
