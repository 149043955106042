import { gql } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import { NativeDeviceInitializer } from 'common/native-app-support/device-initializer/native.device.initializer';
import getNativeAppDetails from 'common/utils/native_app_details';
import getWebAppDetails from 'common/utils/web_app_details';
import { debounce, find, forEach, isError, map, remove } from 'lodash';
import { v4 as uuid } from 'uuid';
import { loggerClient } from '../graphql/client';
import { getCurrentLng } from '../i18n/i18n';
import { LogPlugin } from './../native-app-support/ci-native-plugins/CIPlugins';
import { Logger, UserMeta } from './log.provider';

export const NATIVE_LOG_SOURCE = 'siteMobile';
export interface CIAppLog {
    timestamp: Date;
    sessionId: string;
    topic?: string;
    source: string;
    applicationSource: string;
    level: string;
    message: string[]; // it looks like we can send anything to the BE

    hostname: string;
    location: string;
    browser: object;
    engine: object;
    OSName: string;
    OSVersion: string;
    platform: object;
    language: string;

    userPk?: string;
    studyId?: string;
    subjectId?: string;
    siteId?: string;

    appVersion: string; // Both web and native-site | current version of the application the user is using (so something like ‘1.6.0’)
    deviceId?: string; // native-site only | serial no . of device
    deviceName?: string; //native-site only | eg. Samsung Note
}

const sendLogsGQL = gql`
    mutation ($logs: [InputCreateAppLog!]) {
        logApplicationData(inputs: $logs)
    }
`;

function convertLogToAPI(
    e,
    level: string,
    logMeta: UserMeta,
    appVersion: string,
    serialNumber: string,
    deviceName: string
): CIAppLog {
    const escapedErrors = map(e, (err) => JSON.stringify(err, isError(err) ? Object.getOwnPropertyNames(err) : null));

    let topic = null;
    if (e && e.length > 1 && e[1].topic) {
        topic = e[1].topic;
    }
    //when studyId is passed as the second parameter along with message
    const sitestudyIdArr = e?.length > 1 && find(e, 'studyId');
    const studyId = sitestudyIdArr?.studyId;
    const siteId = sitestudyIdArr?.siteId;

    const subjectIdArr = e?.length > 1 && find(e, 'subjectId');
    const subjectId = subjectIdArr?.subjectId;

    const { userPk, sessionId, hostname, location, browser, engine, os, platform } = logMeta;

    const appLog = {
        timestamp: new Date(),
        userPk,
        sessionId: sessionId || uuid(), // TODO: BE is currently requiring sessionId, but don't have a way of identifying for non-auth users when to generate, so just generating something for now
        topic,
        source: Capacitor.isNativePlatform() ? NATIVE_LOG_SOURCE : 'web',
        applicationSource: 'admin',
        level,
        message: escapedErrors,
        /**
         * this will log (two examples), which can be parsed again:
         * "message": "[\"abc\", {\"studyId\":\"Study.3ee7ec2d-4578-4921-9f14-b52ee64c33bd\",\"message\":\"Study Info tab accessed\"}]",
         * "message": "[{\"stack\":\"Error: xxxx\\n    at _EditStudyDialog (http://localhost:3000/static/js/3.chunk.js:305:90)\\n    at renderWi ....
         */
        hostname,
        location,
        browser,
        engine,
        OSName: os['name'],
        OSVersion: os['version'],
        platform: { type: platform['type'], vendor: platform['vendor'] },
        language: getCurrentLng(),
        studyId,
        siteId,
        subjectId,
        appVersion,
    };

    if (Capacitor.isNativePlatform()) {
        appLog['deviceId'] = serialNumber;
        appLog['deviceName'] = deviceName;
    }

    return appLog;
}

let LOG_STORAGE_KEY = 'ciLogQueue';

function addLogToQueue(log: CIAppLog): void {
    let logStorage = JSON.parse(window.localStorage.getItem(LOG_STORAGE_KEY));
    if (!logStorage) {
        logStorage = [];
    }

    logStorage.push({ id: uuid(), log: log });
    window.localStorage.setItem(LOG_STORAGE_KEY, JSON.stringify(logStorage));
}

let LOG_FLUSH_FREQ = 1000; // One second
let sendLogs = debounce((gqlClient): void => {
    let logStorage = JSON.parse(window.localStorage.getItem(LOG_STORAGE_KEY));

    if (!logStorage || !logStorage.length) {
        return;
    }

    let logs = map(logStorage, 'log');
    let logKeys = map(logStorage, 'id');

    let response = null;
    if (Capacitor.isNativePlatform()) {
        logs = map(logs, (log) => JSON.stringify(log));
        response = LogPlugin.addAppLog({ logs: logs });
    } else {
        response = gqlClient.mutate({
            mutation: sendLogsGQL,
            variables: {
                logs: logs,
            },
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    response.then((result): void => {
        logStorage = JSON.parse(window.localStorage.getItem(LOG_STORAGE_KEY));
        forEach(logKeys, (key): void => {
            // eslint-disable-next-line lodash/prefer-immutable-method
            remove(logStorage, { id: key });
        });
        window.localStorage.setItem(LOG_STORAGE_KEY, JSON.stringify(logStorage));
    });
}, LOG_FLUSH_FREQ);

export default class CIAppLogger implements Logger {
    private gqlClient;
    private appVersion: string = null;
    private serialNumber: string = null;
    private deviceName: string = null;

    // Async but not waited to prevent increase in app load time and there is very less likely chance that log is generated before it gets resolved.
    public async initialize(): Promise<void> {
        if (Capacitor.isNativePlatform()) {
            const nativeAppDetail = await getNativeAppDetails();
            this.appVersion = nativeAppDetail.appVersion;
            this.deviceName = nativeAppDetail.deviceModel;
            this.serialNumber = await NativeDeviceInitializer.getDeviceSerialNumber();
        } else {
            this.appVersion = getWebAppDetails().appVersion;
        }

        this.gqlClient = loggerClient;
        sendLogs(this.gqlClient);
    }

    public log(e, logMeta: UserMeta): void {
        this.writeLog(e, 'debug', logMeta);
    }

    public info(e, logMeta: UserMeta): void {
        this.writeLog(e, 'info', logMeta);
    }

    public warn(e, logMeta: UserMeta): void {
        this.writeLog(e, 'warn', logMeta);
    }

    public error(e, logMeta: UserMeta): void {
        this.writeLog(e, 'error', logMeta);
    }

    private writeLog(e, level, logMeta): void {
        let log = convertLogToAPI(e, level, logMeta, this.appVersion, this.serialNumber, this.deviceName);
        addLogToQueue(log);
        sendLogs(this.gqlClient);
    }
}
