import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { RouteType } from 'common/types/route';
import { preloadLazyComponent } from 'common/utils/loading';

export const routes: RouteType[] = [
    {
        component: preloadLazyComponent(() => import('module/sitedashboard/SiteDashboardPage')),
        permissions: [
            'StudyInstanceAppointment_Create',
            'Patient_Create',
            'Patient_Read',
            'StudyInstanceAppointment_Read',
            'StudyInstanceAppointment_Edit',
            'Patient_Edit',
        ],
        path: '/',
        role: [
            BaseRoles.AdminId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.Inspector,
            RoleVariantEnum.ProductSupport,
        ],
        studySiteRequired: true,
    },
];
