import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';

export let routes = [
    {
        component: lazy(() => import('module/policy/policy')),
        permissions: ['SubscriberPP_Read', 'SubscriberPP_Create', 'SubscriberPP_Edit', 'SubscriberPP_Delete'],
        role: [
            BaseRoles.AdminId,
            BaseRoles.UserId,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.Librarian,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.TranslationRepresentative,
            RoleVariantEnum.Monitor,
            RoleVariantEnum.DataManager,
            RoleVariantEnum.ClinicalTrialLead,
            RoleVariantEnum.Inspector,
            RoleVariantEnum.BlindedStudyManager,
            RoleVariantEnum.ProductSupport,
        ],
        path: '/',
    },
];
