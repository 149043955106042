import { AuthenticatedRoute } from 'common/auth/auth_route';
import { AuthUserType, BaseRoles, RoleVariantEnum, UserSubType } from 'common/graphql/types';
import { OfflineManager } from 'common/native-app-support/native.offline.manager';
import { browserHistory } from 'common/routing/browser_history';
import { isSponsorUser, SPONSOR_HOME_PATH } from 'common/utils/sponsorUtils';
import { get, includes, map } from 'lodash';
import { routes as activeStudySiteRoutes } from 'module/activestudysite/routes';
import { routes as activityRoutes } from 'module/activity/routes';
import { routes as activitybuilderRoutes } from 'module/activitybuilder/routes';
import { routes as activitylibraryRoutes } from 'module/activitylibrary/routes';
import { routes as adminRoutes } from 'module/administration/routes';
import { routes as authRoutes } from 'module/auth/routes';
import { routes as customerRoutes } from 'module/customer/routes';
import { routes as devicesRoutes } from 'module/devices/routes';
import { routes as econsentRoutes } from 'module/econsent/routes';
import { routes as econsentbuilderRoutes } from 'module/econsentbuilder/routes';
import { routes as eosRoutes } from 'module/eosdashboard/routes';
import { routes as manageSitesRoutes } from 'module/managesites/routes';
import { routes as reportRoutes } from 'module/mydata/routes';
import { routes as patientRoutes } from 'module/patient/routes';
import { routes as patientAppRoutes } from 'module/patientApp/routes';
import { routes as policyRoutes } from 'module/policy/routes';
import { routes as protocolRoutes } from 'module/protocoldesigner/routes';
import { routes as regionRoutes } from 'module/region/routes';
import { routes as siteRoutes } from 'module/site/routes';
import { routes as siteDashboardRoutes } from 'module/sitedashboard/routes';
import { routes as sponsorRoutes } from 'module/sponsor/routes';
import { routes as sponsorDashboardRoutes } from 'module/sponsordashboard/routes';
import { routes as studyRoutes } from 'module/study/routes';
import { routes as studydetailsRoutes } from 'module/studydetails/routes';
import { routes as studyHistoryRoutes } from 'module/studyhistory/routes';
import { routes as studyReportRoutes } from 'module/studyreports/routes';
import { routes as studysitealertRoutes } from 'module/studysitealerts/routes';
import { routes as subscriberRoutes } from 'module/subscriber/routes';
import { routes as userRoutes } from 'module/user/routes';
import { lazy, memo, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { RootState } from 'store';

const LoginPage = lazy(() => import('module/auth/login/login.page'));

const configureRoute = (pathPrefix, routes) =>
    map(routes, (route) => ({
        ...route,
        path: `${pathPrefix}${route.path}`,
    }));

const routeDefinitions = [].concat(
    configureRoute('', authRoutes),
    configureRoute('', activityRoutes),
    configureRoute('/activitybuilder', activitybuilderRoutes),
    configureRoute('/activitylibrary', activitylibraryRoutes),
    configureRoute('/customer', customerRoutes),
    configureRoute('/site', siteRoutes),
    configureRoute('/sponsor', sponsorRoutes),
    configureRoute('', studyRoutes),
    configureRoute('', patientRoutes),
    configureRoute('', protocolRoutes),
    configureRoute('/econsentbuilder', econsentbuilderRoutes),
    configureRoute('', econsentRoutes),
    configureRoute('/subscriber', subscriberRoutes),
    configureRoute('', userRoutes),
    configureRoute('', adminRoutes),
    configureRoute('', devicesRoutes),
    configureRoute('/policy', policyRoutes),
    configureRoute('', studydetailsRoutes),
    configureRoute('', manageSitesRoutes),
    configureRoute('/siteDashboard', siteDashboardRoutes),
    configureRoute('', reportRoutes),
    configureRoute('', patientAppRoutes),
    configureRoute('', studyReportRoutes),
    configureRoute('', studysitealertRoutes),
    configureRoute('/region', regionRoutes),
    configureRoute('', studyHistoryRoutes),
    configureRoute('', sponsorDashboardRoutes),
    configureRoute('', activeStudySiteRoutes),
    configureRoute('', eosRoutes)
);

const LandingPage = (): ReactElement => {
    const auth = useSelector((state: RootState) => state.auth);
    const { activeUser } = auth;

    const studyUsers = [RoleVariantEnum.StudyBuilder, RoleVariantEnum.StudySupport];

    if (!activeUser) {
        return <LoginPage />;
    }

    if (activeUser.userType === AuthUserType.PATIENT) {
        const isSecurityQuestionSetUp = get(activeUser, 'isSecurityQuestionSetUp', false);
        return isSecurityQuestionSetUp ? <Redirect to="/patientDashboard" /> : <Redirect to="/activate" />;
    }

    if (
        !OfflineManager.getInstance().isPhoneOffline() &&
        activeUser.currentPolicy?.[0] &&
        activeUser.acceptedVersionPP?.toString() !== activeUser.currentPolicy[0].majorVersion.toString() &&
        includes(activeUser.permissions, 'SubscriberPP_Read') // When updating permission here make sure to update in switch user
    ) {
        return <Redirect to="/policy" />;
    }

    if (
        activeUser.subType === UserSubType.SITE ||
        activeUser.subType === UserSubType.MONITOR ||
        activeUser.subType === UserSubType.DATA_MANAGER ||
        activeUser.subType === UserSubType.INSPECTOR ||
        activeUser.subType === UserSubType.PRODUCT_SUPPORT
    ) {
        if (!auth.activeStudySite.activeStudySiteReady && !!auth?.activeUser?.userStudies?.length) {
            return <Redirect to="/activestudysite" />;
        }
        const homePath = isSponsorUser(activeUser) ? SPONSOR_HOME_PATH : '/siteDashboard';
        return <Redirect to={homePath} />;
    }

    if (
        (BaseRoles.AdminId === activeUser?.roleBClaim?.sk && !includes(activeUser.permissions, 'Subscriber_Create')) ||
        RoleVariantEnum.ClinicalTrialLead === activeUser?.roleVClaim?.sk ||
        RoleVariantEnum.BlindedStudyManager === activeUser?.roleVClaim?.sk ||
        includes(studyUsers, activeUser?.roleVClaim?.sk)
    ) {
        return <Redirect to="/study" />;
    }

    // since the subscriber admin and sys admin share the same base role
    if (activeUser?.role === RoleVariantEnum.SystemAdminstrator) {
        return <Redirect to="/subscriber" />;
    }

    if (RoleVariantEnum.Librarian === activeUser?.roleVClaim?.sk) {
        return <Redirect to="/activitylibrary" />;
    }

    if (RoleVariantEnum.TranslationRepresentative === activeUser?.roleVClaim?.sk) {
        return <Redirect to="/userprofile/general" />;
    }

    return <Redirect to="/dashboard" />;
};

const AppRouter = (): ReactElement => (
    <Router history={browserHistory}>
        <Switch>
            <Route path={['/', '/login']} exact>
                <LandingPage />
            </Route>
            {map(routeDefinitions, (route): ReactElement => {
                if (route.redirects) {
                    return (
                        <Route key={route.path} path={route.path} exact>
                            <Redirect to={route.redirects} />
                        </Route>
                    );
                }

                if (!route.permissions?.length && !route.role?.length) {
                    const AsyncComponent = route.component;
                    return (
                        <Route key={route.path} path={route.path} exact>
                            <AsyncComponent routes={routeDefinitions} />
                        </Route>
                    );
                }

                return (
                    <AuthenticatedRoute
                        key={route.path}
                        path={route.path}
                        exact
                        permissions={route.permissions}
                        role={route.role}
                        component={route.component}
                        accessRoute={route}
                    />
                );
            })}
            <Route>
                <div>404 Not Found</div>
            </Route>
        </Switch>
    </Router>
);

export default memo(AppRouter);
