import WarningIcon from '@mui/icons-material/Warning';
import { FormHelperText, TextField, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { getCurrentLng, isRTL } from 'common/i18n/i18n';
import { Field } from 'common/utils/validations';
import Inputmask from 'inputmask';
import { isEmpty, isNil, isUndefined, map } from 'lodash';
import { FC, MutableRefObject, ReactElement, useEffect, useState } from 'react';
import '../form_fields.scss';
import { CIFieldProps } from './fields.interface';

export interface CITextProps extends CIFieldProps {
    showTooltip?: boolean;
}

export const CITextField: FC<CITextProps> = (props): ReactElement => {
    let {
        // Props
        id,
        t,
        labelI18NKey,
        type,
        name,
        rowsMax,
        variant = 'standard',
        placeholder,
        autoFocus,
        margin,
        fullWidth,
        labelShrink,
        helperText,
        multiline,
        endAdornment,
        showErrorOnLoad,
        rows,
        className,
        disabled,
        // Field Validations
        validations = [],
        required,
        maxLength,
        maxValue,
        minValue,
        // Field Warning Validations
        warningValidations = [],
        // Field Formatting
        formats = [],
        format = null,
        formatOnBlur = true,
        trimWhiteSpaces = true,
        readOnly = false,
        autoComplete = 'off',
        inputRef = null,
        optionWidthDifference,
        maskRegex,
        mask,
        maskOptions = {},
        maskAlias,
        maskbdi, //If False mask would push the Hardcoded string to last on RTL languages
        step,
        onChange: parentOnChange,
        ariaLabel,
        showTooltip = false,
    } = props;

    const [state, setState] = useState({ autofilled: undefined });
    const direction = isRTL(getCurrentLng().substring(0, 2)) ? 'rtl' : 'ltr';

    // update autofill state, make sure each event correspond to the correct input
    function handleAutoFill(e): void {
        if (e.animationName === 'onautofillstart') {
            setState({
                autofilled: true,
            });
        } else if (e.animationName === 'onautofillcancel') {
            setState({
                autofilled: undefined,
            });
        }
    }

    /**
     * autoUnmask: true -> To Preserve existing validations. Can be overridded if needed on maskOptions
     * showMaskOnHover: false -> To hide mask on mouse over in Internet Explorer
     */
    useEffect(() => {
        if ((isUndefined(maskRegex) && isUndefined(maskAlias) && isUndefined(mask)) || isNil(inputRef)) {
            return;
        }
        const textInput = (inputRef as MutableRefObject<HTMLInputElement>).current;
        if (!!maskRegex) {
            Inputmask({
                regex: maskRegex,
                ...{
                    showMaskOnHover: false,
                    ...maskOptions,
                    oncomplete: (): void => {
                        // This passes the entered value to the event handler instead of the `event` itself
                        // as we cannot trigger a change event from `oncomplete` of InputMask
                        //@ts-ignore
                        parentOnChange?.(textInput.value);
                    },
                },
            }).mask(textInput);
        } else if (!!mask) {
            Inputmask({ mask: mask, ...{ autoUnmask: true, showMaskOnHover: false, ...maskOptions } }).mask(textInput);
        } else if (!!maskAlias) {
            Inputmask({ alias: maskAlias, ...{ autoUnmask: true, showMaskOnHover: false, ...maskOptions } }).mask(
                textInput
            );
        } else if (maskRegex === '' || mask === '') {
            //There is a TypeError issue on inputMask after removing the mask with remove() method ==> Inputmask.remove(textInput);
            //Workaround for now until it gets fixed
            Inputmask({ mask: '', ...{ autoUnmask: true, showMaskOnHover: false, ...maskOptions } }).mask(textInput);
        }
    }, [maskRegex, mask, maskAlias]);

    const finalFormFormat =
        format !== null
            ? {
                  format,
              }
            : {};

    return (
        <Field
            t={t}
            name={name}
            type={type}
            required={required}
            trimWhiteSpaces={trimWhiteSpaces}
            formats={formats}
            {...finalFormFormat}
            formatOnBlur={formatOnBlur}
            validations={validations}
            render={({ input, meta: { touched, error, modified } }): ReactElement => {
                const onChangeHandler = (event): void => {
                    input?.onChange(event.target.value);
                    // If Input is masked, call the parentOnChange only after Input is complete (which is
                    // handled by InputMask's oncomplete method)
                    if (isEmpty(maskRegex)) {
                        parentOnChange?.(event.target.value);
                    }
                };

                // must define a initial value, if we dont
                // react throws error about us switching from controlled to uncontrolled
                const inputConfig = {
                    ...input,
                    value: !isNil(input.value) ? input.value : '',
                };

                if (optionWidthDifference) {
                    error = 'error.control_option_width';
                }

                const showError = !!((touched || showErrorOnLoad || modified || optionWidthDifference) && error); // Must return a boolean, otherwise MUI will complain about receiving a string.
                labelShrink = state.autofilled || labelShrink; // if filled, set true, otherwise fall back to prop, or undefined(auto)

                const label = labelI18NKey ? t(labelI18NKey) : '';

                let textField = (
                    <TextField
                        className={classNames('CIText-Field', className, {
                            'has-value': !!input?.value,
                            unicodebidi: maskbdi,
                        })} //To preserve the format during regex/pattern mask on RTL languages with only digits & Hardcoded string in the middle
                        disabled={disabled}
                        id={id}
                        name={name}
                        inputRef={inputRef}
                        // having trouble with variant props, there are few very fugly solutions
                        // @ts-ignore // https://github.com/mui-org/material-ui/issues/15697
                        variant={variant}
                        label={label}
                        placeholder={placeholder && t(placeholder)}
                        margin={margin}
                        error={showError}
                        fullWidth={fullWidth}
                        autoFocus={autoFocus}
                        multiline={multiline}
                        rows={rows}
                        maxRows={rowsMax}
                        required={required}
                        helperText={showError ? error : helperText}
                        InputLabelProps={{ shrink: labelShrink }}
                        //inputProps has been nested inside of InputProps to avoid a duplicate props error. InputProps is for MUI
                        // https://github.com/mui-org/material-ui/issues/8232
                        InputProps={{
                            endAdornment,
                            inputProps: {
                                readOnly,
                                maxLength,
                                'aria-required': required,
                                onAnimationStart: (e): void => handleAutoFill(e),
                                min: minValue,
                                max: maxValue,
                                autoComplete,
                                step,
                                'aria-label': ariaLabel || label,
                                direction,
                            },
                        }}
                        {...inputConfig}
                        onChange={onChangeHandler}
                    />
                );
                if (showTooltip) {
                    textField = <Tooltip title={inputConfig.value ?? ''}>{textField}</Tooltip>;
                }

                return (
                    <>
                        {textField}
                        {map(warningValidations, (validation) => {
                            // This setup may not work for some validator functions (but that is currently not needed), since
                            // 1. the meta object should contain additional data
                            // 2. we do not have access to all form values which are provided by Final Form in the regular validations logic
                            const meta = {
                                t,
                                name,
                                blur: () => {},
                                change: () => {},
                                focus: () => {},
                            };
                            const allFormValues = undefined;
                            const result = validation(input?.value, allFormValues, meta);
                            return (
                                result && (
                                    <FormHelperText className="warning-helper-text">
                                        <WarningIcon fontSize="small" />
                                        {result}
                                    </FormHelperText>
                                )
                            );
                        })}
                    </>
                );
            }}
        />
    );
};
