import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';
export let routes = [
    {
        component: lazy(() => import('module/sponsor/SponsorListPage')),
        permissions: ['Sponsor_Read', 'Sponsor_Create', 'Sponsor_Edit', 'Sponsor_Delete'],
        role: [BaseRoles.AdminId, BaseRoles.UserId, RoleVariantEnum.StudySupport, RoleVariantEnum.StudyBuilder],
        path: '/',
    },
];
