import { gql } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import { AuditCreationEventsEnum } from 'common/log/auditevents';
import { LogPlugin } from 'common/native-app-support/ci-native-plugins/CIPlugins';
import { getCurrentLocaleTimestamp, getCurrentTimezone } from 'common/utils/datetime';
import { debounce, keys, remove } from 'lodash';
import { ComponentType, FC, ReactElement, createContext, memo } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { AuthState, AuthUser } from '../auth/auth_context';
import { loggerClient } from '../graphql/client';
import { NATIVE_LOG_SOURCE } from './ci.logger';

interface CIAuditLog {
    localTimestamp: Date | string;
    localTimezone: string;
    source: string;

    studyId: string;
    siteId?: string;
    subjectId?: string;
    relatedEntityId: string;
    relatedEntityName: string;
    applicationSource: string;
    action: string;
    message: string;
    data: object;
}

const sendLogGQL = gql`
    mutation ($log: InputCreateApplicationAuditData!) {
        createApplicationAuditData(input: $log) {
            pk
        }
    }
`;

function convertLogToAPI(action, message, data, meta): CIAuditLog {
    return {
        localTimestamp: getCurrentLocaleTimestamp(),
        localTimezone: getCurrentTimezone(),
        source: '0.0.0.0', // Intended to be IP address, but we don't have access... BE will augment with value from header
        relatedEntityName: meta?.relatedEntityName,
        studyId: meta?.studyId,
        siteId: meta?.siteId,
        subjectId: meta?.subjectId,
        relatedEntityId: meta?.relatedEntityId,
        applicationSource: Capacitor.isNativePlatform() ? NATIVE_LOG_SOURCE : 'WEB',
        action,
        message,
        data,
    };
}

let LOG_STORAGE_KEY = 'ciAuditQueue';

function addLogToQueue(log: CIAuditLog): void {
    let logStorage = JSON.parse(window.localStorage.getItem(LOG_STORAGE_KEY));
    if (!logStorage) {
        logStorage = [];
    }

    const activeUser: string = localStorage.getItem('activeUser');
    const userPk = (JSON.parse(activeUser) as AuthUser)?.pk;
    // Associate userId for each log who generated it so that can be send to server even after logout
    // TODO : support patient's logs as well in future for now userPk will result undefined
    logStorage.push({ id: uuid(), log: log, userPk });
    window.localStorage.setItem(LOG_STORAGE_KEY, JSON.stringify(logStorage));
}

let LOG_FLUSH_FREQ = 1000; // One second
let sendLogs = debounce((gqlClient): void => {
    let logStorage = JSON.parse(window.localStorage.getItem(LOG_STORAGE_KEY));

    if (!logStorage || !logStorage.length) {
        return;
    }

    // let logs = map(logStorage, 'log');
    // let logKeys = map(logStorage, 'id');

    for (let logRecord of logStorage) {
        let result = null;
        if (Capacitor.isNativePlatform()) {
            result = LogPlugin.addAuditLog({
                log: JSON.stringify(logRecord.log, keys(logRecord.log).sort()),
                userId: logRecord.userPk,
            });
        } else {
            result = gqlClient.mutate({
                mutation: sendLogGQL,
                variables: {
                    log: logRecord.log,
                },
            });
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-loop-func
        result.then((result): void => {
            logStorage = JSON.parse(window.localStorage.getItem(LOG_STORAGE_KEY));
            // eslint-disable-next-line lodash/prefer-immutable-method
            remove(logStorage, { id: logRecord.id });

            // parallel safe?
            window.localStorage.setItem(LOG_STORAGE_KEY, JSON.stringify(logStorage));
        });
    }
}, LOG_FLUSH_FREQ);

export class CIAuditLogger {
    private static __instance: CIAuditLogger = new CIAuditLogger();

    private gqlClient;
    private authUser: AuthState;

    private constructor() {
        this.initialize();
    }

    private initialize(): void {
        this.gqlClient = loggerClient;
        sendLogs(this.gqlClient);
    }

    public setAuth(auth: AuthState): void {
        this.authUser = auth;
    }

    public static getInstance(): CIAuditLogger {
        return CIAuditLogger.__instance;
    }

    public log(action, message, data, meta): void {
        this.writeLog(action, message, data, meta);
    }

    private writeLog(action, message, data, meta): void {
        let studyId = meta?.studyId || this.authUser?.activeStudySite?.activeStudy?.studyId;
        let siteId = meta?.siteId || this.authUser?.activeStudySite?.activeSite?.siteId;

        if (!meta) {
            meta = {};
        }
        if (action !== AuditCreationEventsEnum.Logout && action !== AuditCreationEventsEnum.Login) {
            meta.studyId = studyId;
            meta.siteId = siteId;
        }
        let log = convertLogToAPI(action, message, data, meta);
        addLogToQueue(log);

        sendLogs(this.gqlClient);
    }
}

const AuditLoggingContext = createContext(CIAuditLogger.prototype);

export function AuditLoggerProvider(WrappedComponent): ComponentType {
    interface Props {
        auth: AuthState;
    }

    const _LoggerProvider: FC<Props> = (props): ReactElement => {
        const logger = CIAuditLogger.getInstance();
        logger.setAuth(props.auth);

        return (
            <AuditLoggingContext.Provider value={logger}>
                <WrappedComponent />
            </AuditLoggingContext.Provider>
        );
    };

    const LoggerProvider = memo(_LoggerProvider, (prevProps, nextProps): boolean => nextProps.auth === prevProps.auth);

    const mapStateToProps = ({ auth }): object => ({ auth });

    return connect(mapStateToProps)(LoggerProvider);
}

export function withAuditLogger(Component): ComponentType {
    return function WrapperComponent(props): ReactElement {
        return (
            <AuditLoggingContext.Consumer>
                {(logger): ReactElement => <Component {...props} auditLogger={logger} />}
            </AuditLoggingContext.Consumer>
        );
    };
}
