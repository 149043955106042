import { AuthUser } from 'common/auth/auth_context';
import { UserSubType } from 'common/graphql/types';
import { SiteStudy } from 'common/types/site';
import { History } from 'history';
import { find, isNil, isUndefined } from 'lodash';

export const DEFAULT_PATH = '/';

/**
 * IF User opened a deep link, navigate to deep link. [Deep Link > last Saved Page]
 * Redirects to last saved URL if user has one.
 *  Else redirects to targetPath under following conditions
 *      If user dont have access to the saved study site anymore
 * @param targetPath '/' if normal login & '<PATH>' if Deep Links
 * @param activeUser Active User
 * @param updateActiveStudySite redux dispatcher to update active study and site
 * @param history hisory for url redirection
 * @returns void
 */
export function redirectToLastPagesOpened(
    targetPath: string,
    activeUser: AuthUser,
    updateActiveStudySite: (SiteStudy: SiteStudy) => void,
    history: History
): void {
    if (targetPath === DEFAULT_PATH) {
        // If User Logs in normally, without deep link
        const lastOpenedData = activeUser.lastPagesOpened;
        if (!isNil(lastOpenedData) && !!lastOpenedData.length) {
            const { studyId, siteId, url } = lastOpenedData[0]; // [0] -> For now we resume only one session

            if (
                activeUser.subType === UserSubType.SITE ||
                activeUser.subType === UserSubType.MONITOR ||
                activeUser.subType === UserSubType.DATA_MANAGER ||
                activeUser.subType === UserSubType.INSPECTOR ||
                activeUser.subType === UserSubType.PRODUCT_SUPPORT
            ) {
                const userstudies = activeUser?.userStudies || [];
                const userSelectedStudy = find(userstudies, { studyId });
                const usersites = userSelectedStudy?.userStudySites || [];
                const userSelectedSite = find(usersites, { siteId });

                if (!isUndefined(userSelectedStudy) && !isUndefined(userSelectedSite)) {
                    const siteStudy: SiteStudy = {
                        site: userSelectedSite,
                        study: userSelectedStudy,
                    };
                    updateActiveStudySite(siteStudy);
                    // Navigate to last URL - SITE or MONITOR users
                    history.push(url);
                    return;
                }
            } else {
                // Navigate to last URL - Other Users
                history.push(url);
                return;
            }
        }
    }
    history.push(targetPath);
}
