import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';

export let routes = [
    {
        component: lazy(() => import('module/customer/CustomerListPage')),
        permissions: ['Customer_Read', 'Customer_Create', 'Customer_Edit', 'Customer_Delete'],
        role: [BaseRoles.AdminId, RoleVariantEnum.StudySupport, RoleVariantEnum.StudyBuilder],
        path: '/',
    },
];
