import { BaseRoles } from 'common/graphql/types';
import { preloadLazyComponent } from 'common/utils/loading';

export let routes = [
    {
        component: preloadLazyComponent(() => import('module/patientApp/dashboard/patient_dashboard.page')),
        permissions: [
            'StudyInstanceTaskResult_Create',
            'StudyInstanceTaskResult_Edit',
            'StudyInstanceTaskMetric_Read',
            'StudyInstanceTaskMetric_Create',
            'StudyInstanceTaskMetric_Edit',
        ],
        role: [BaseRoles.UserId],
        path: '/patientDashboard',
        studySiteRequired: false,
    },
    {
        component: preloadLazyComponent(() => import('module/patientApp/activate/activate.page')),
        permissions: [],
        role: [],
        path: '/activate',
    },
    {
        component: preloadLazyComponent(() => import('module/patientApp/clinicDashboard/clinic_dashboard.page')),
        permissions: [
            'StudyInstanceTaskResult_Create',
            'StudyInstanceTaskResult_Edit',
            'StudyInstanceTaskMetric_Read',
            'StudyInstanceTaskMetric_Create',
            'StudyInstanceTaskMetric_Edit',
        ],
        role: [BaseRoles.UserId],
        path: '/myClinic',
    },
    {
        component: preloadLazyComponent(() => import('module/patientApp/profile/patient_profile.page')),
        permissions: [
            'StudyInstanceTaskResult_Create',
            'StudyInstanceTaskResult_Edit',
            'StudyInstanceTaskMetric_Read',
            'StudyInstanceTaskMetric_Create',
            'StudyInstanceTaskMetric_Edit',
        ],
        role: [BaseRoles.UserId],
        path: '/patientProfile',
    },
    {
        component: preloadLazyComponent(() => import('module/patientApp/profile/patient_profile.page')),
        permissions: [
            'StudyInstanceTaskResult_Create',
            'StudyInstanceTaskResult_Edit',
            'StudyInstanceTaskMetric_Read',
            'StudyInstanceTaskMetric_Create',
            'StudyInstanceTaskMetric_Edit',
        ],
        role: [BaseRoles.UserId],
        path: '/patientProfile/:view',
    },
    {
        component: preloadLazyComponent(() => import('module/patientApp/library/library.page')),
        permissions: [
            'StudyInstanceTaskResult_Create',
            'StudyInstanceTaskResult_Edit',
            'StudyInstanceTaskMetric_Read',
            'StudyInstanceTaskMetric_Create',
            'StudyInstanceTaskMetric_Edit',
        ],
        role: [BaseRoles.UserId],
        path: '/myLibrary',
    },
    {
        component: preloadLazyComponent(() => import('module/patientApp/econsent/econsent.page')),
        permissions: [
            'StudyInstanceTaskResult_Create',
            'StudyInstanceTaskResult_Edit',
            'StudyInstanceTaskMetric_Read',
            'StudyInstanceTaskMetric_Create',
            'StudyInstanceTaskMetric_Edit',
        ],
        role: [BaseRoles.UserId],
        path: '/econsent/:pk/:sk',
    },
    {
        component: preloadLazyComponent(() => import('module/patientApp/status/status.page')),
        permissions: [
            'StudyInstanceTaskResult_Create',
            'StudyInstanceTaskResult_Edit',
            'StudyInstanceTaskMetric_Read',
            'StudyInstanceTaskMetric_Create',
            'StudyInstanceTaskMetric_Edit',
        ],
        role: [BaseRoles.UserId],
        path: '/myStatus',
    },
    {
        component: preloadLazyComponent(() => import('module/patientApp/activity/activity.page')),
        permissions: [
            'StudyInstanceTaskResult_Create',
            'StudyInstanceTaskResult_Edit',
            'StudyInstanceTaskMetric_Read',
            'StudyInstanceTaskMetric_Create',
            'StudyInstanceTaskMetric_Edit',
        ],
        role: [BaseRoles.UserId],
        path: '/activity/:pk/:sk/:instanceId/:type?',
    },
];
