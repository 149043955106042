import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';
export let routes = [
    {
        component: lazy(() => import('module/studyhistory/study_history.page')),
        permissions: ['Study_Read', 'Study_Create', 'Study_Edit', 'Study_Delete'],
        path: '/studyhistory/:studyId/details',
        role: [
            BaseRoles.AdminId,
            RoleVariantEnum.StudySupport,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.Librarian,
        ],
    },
];
